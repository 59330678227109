<template>
	<!-- 顶部topBar -->
	<div class="tabs">
		<div class="tab-item" v-for="(item, index) in tabs" :key="index" :class="{ active: index === tabNum }"
			@click="tabClick(index)">
			{{ item }}
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			tabs: Array
		},
		data() {
			return {
				tabNum: 0
			}
		},
		methods: {
			tabClick(index) {
				this.tabNum = index
				this.$emit('tabNum', index)
			},
		}
	}
</script>

<style lang="less" scoped>
	.tabs {
		display: flex;
		margin-bottom: 10px;
		padding: 10px 0px;
		font-size: 16px;
		justify-content: space-between;
		color: #a6a6a6;
		width: 76%;

		.tab-item {
			height: 6px;
			line-height: 6px;
			text-align: center;
		}

		.active {
			color: #000000;
			font-weight: bold;

			&::after {
				content: '';
				display: inline-block;
				width: 100%;
				height: 5px;
				background-color: #47AFA7;
				position: relative;
				bottom: -3px;
				left: 0px;
			}
		}
	}
</style>
