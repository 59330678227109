<template>
	<div class="parentAttendance">
		<div class="my-card" :class="value?'':'activeHeight'">
			<!-- 顶部用户数据 -->
			<div class="top">
				<div class="left">
					<van-image width="40" height="40" radius='30%' :src="ProfilePicture" class="img" />
					<span style="margin-left: 10px;">{{ChildrenName}}</span>
				</div>
				<!-- 日历切换 -->
				<div class="right">
					<van-tabs type="card" v-model='value'>
						<van-tab title="日"></van-tab>
						<van-tab title="月"></van-tab>
					</van-tabs>
				</div>
			</div>
			<!-- 日历数据 -->
			<div class="calendarInfo" v-show="!value">
				<div class="date">
					<span>{{year}}</span>
					<span class="line"></span>
					<span>{{mouthDay}}</span>
				</div>
				<!-- 日数据组件-->
				<dayInfo ref="dayInfo" v-if="!value"></dayInfo>
			</div>
			<!-- 月历数据 -->
			<mouthInfo ref="mouthInfo" v-show="value"> </mouthInfo>
		</div>
		<!-- 底部天数类型选择 -->
		<div class="bottom" style="margin-top: 40px;" v-show="value">
			<div class="my-card">
				<topBar ref='topBar' :tabs="['出勤天数','请假天数','休息天数']" style="font-size: 14px;" @tabNum='tabClick'
					class="topBar">
				</topBar>
				<!-- 日期选择 -->
				<div class="selectType">
					<div v-for="item in dayList" :key="item.Date">
						<div class="box" v-if="dayList.length > 0">
							<div class="left">
								{{item.Date}}
							</div>
							<div class="right" @click='dayDetails(item)'>
								<van-icon name="arrow" />
							</div>
						</div>
					</div>
					<div class="" v-show='dayList.length == 0'
						style="text-align: center;margin-top: 20px;font-size: 16px;">
						暂无数据
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Image as VanImage,
		Tab,
		Tabs,
		Calendar,
		Icon
	} from 'vant'

	import topBar from '@/components/common/topBar/index.vue'
	import dayInfo from './dayInfo/index.vue'
	import mouthInfo from './mouthInfo/index.vue'


	export default {
		name: 'parentAttendance',
		components: {
			topBar,
			dayInfo,
			mouthInfo,
			[VanImage.name]: VanImage,
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
			[Calendar.name]: Calendar,
			[Icon.name]: Icon
		},
		data() {
			return {
				ChildrenName: JSON.parse(window.localStorage.getItem('userInfo')).OpenChildren?.ChildrenName,
				ProfilePicture: JSON.parse(window.localStorage.getItem('userInfo')).OpenChildren?.ProfilePicture,
				show: true,
				year: '',
				mouthDay: '',
				value: 0,
				currentDay: '',
				tabNum: 0,
				dayList: [], //底部月分下天数考勤数据
				AttendanceDayDtos: [], //出勤天数数据
				RestDayDtos: [], //休息天数数据
				LeaveDayDtos: [], //请假天数数据
				ChildrenId: '',
			}
		},
		mounted() {
			// 存在id,由教师端查看详情进入
			if (this.$route.query.id) {
				this.ChildrenId = this.$route.query.id
				this.value = this.$route.query.time.dayMonth - 1 //跳转对应日,月
				// 存在详情页跳转,携带日的日期数据
				if (this.$route.query.time.data) {
					this.$nextTick(() => {
						this.$refs.dayInfo.$refs.calendar.currentDate = new Date(this.$route.query.time.data)
						this.$refs.dayInfo.ChildrenId = this.ChildrenId
						this.$refs.dayInfo._getMoutnState()
					})
				} else {
					// 详情统计跳转 ,月切换到日(取当天数据)
					this.$refs.dayInfo.$refs.calendar.currentDate = new Date()
					this.$refs.dayInfo.ChildrenId = this.ChildrenId
					this.$refs.dayInfo._getMoutnState()
				}
				this.$refs.mouthInfo.ChildrenId = this.ChildrenId
				if (this.$route.query.time.mouthDay) {
					this.$refs.mouthInfo.mouthDay = this.$route.query.time.mouthDay + '月'
					this.$refs.mouthInfo.currentIndex = this.$route.query.time.mouthDay - 1 //月当前索引值	
					this.$refs.mouthInfo._getMouthAttence()
				} else {
					// 详情统计跳转 ,日切换到月(取当月数据)
					this.$refs.mouthInfo.mouthDay = Number(new Date().getMonth()) + 1 + '月'
					this.$refs.mouthInfo.currentIndex = Number(new Date().getMonth()) //月当前索引值	
					this.$refs.mouthInfo._getMouthAttence()
				}
			} else {
				this.$nextTick(() => {				
					this.$refs.dayInfo._getMoutnState()
				})
				// 伙食费跳转查看统计详情
				console.log(this.$route.query.time)
				if(this.$route.query.time){
					// 切换到月
					this.value = 1
					this.$refs.mouthInfo.year = this.$route.query.time.year
					this.$refs.mouthInfo.mouthDay = this.$route.query.time.mouthDay + '月'
					this.$refs.mouthInfo.currentIndex = this.$route.query.time.mouthDay - 1 //月当前索引值	
					this.$refs.mouthInfo._getMouthAttence()
				}else {
					this.$refs.mouthInfo._getMouthAttence()
				}
				
			}
		},
		methods: {
			// 底部具体选中某一天
			dayDetails(item) {
				this.value = 0
				// 日历组件数据改变			
				this.$nextTick(() => {
					this.$refs.dayInfo.$refs.calendar.currentDate = new Date(item.Date)
					this.currentDay = item.Date
					this.mouthDay = item.Date.slice(5)
					//this.currentDay = item.Date
					// 刷新底部日历出勤状态
					this.$refs.dayInfo._getMoutnState()
				})
			},
			// 底部按钮切换
			tabClick(index) {
				this.tabNum = index
				this.dayList = []
				console.log(this.tabNum)
				this.dayList = this.tabNum == 0 ? this.AttendanceDayDtos : this.tabNum == 1 ? this.LeaveDayDtos : this
					.RestDayDtos
				console.log(this.AttendanceDayDtos)
				if (this.dayList.length > 0) {
					this.dayList.map(item => {
						item.Date = this.$moment(new Date(item.Date)).format("YYYY-MM-DD")
					})
				}
			},
		}
	}
</script>

<style lang="less">
	.parentAttendance {
		padding: 10px 12px;
		height: 100%;

		.activeHeight {
			height: 80% !important;
		}

		.calendarInfo {
			margin-top: 20px;
			width: 100%;
			height: 60%;

			.attendceDay {
				display: flex;
				width: 68%;
				justify-content: space-between;
				//padding-left: 10px;
				//margin-top: 30px;
				margin:20px auto 0;

				div {
					text-align: center;
					justify-content: space-between;

					p:first-child {
						font-size: 32px;
					}

					p:last-child {
						margin-top: 4px;
					}
				}
			}

			.mouthDay {
				display: flex;
				flex-wrap: wrap;

				.box {
					min-width: 25px;
					height: 25px;
					padding: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
				}

				.active {
					background-color: #47AFA7;
				}
			}

			.date {
				height: 15px;
				position: relative;
				line-height: 15px;
				display: flex;
				align-items: center;

				.line {
					display: inline-block;
					width: 1.3px;
					height: 12px;
					background-color: #ebedf0;
					margin: 0 8px;
					position: relative;
					bottom: 1px;
					left: 1px;
				}

				span {
					font-size: 16px;
				}
			}
		}

		.my-card {
			height: 300px;

			.top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #ebedf0;
				padding-bottom: 10px;

				.left {
					display: flex;
					align-items: center;
				}
			}
		}

		.bottom {
			height: 40%;

			.my-card {
				height: 100%;
				display: flex;
				flex-direction: column;

				.selectType {
					flex: 1;
					overflow: scroll;

					.box {
						height: 30px;
						border-bottom: 1px solid #ebedf0;
						display: flex;
						justify-content: space-between;
						align-items: center;
						color: #a6a6aa;
						font-size: 16px;
						padding: 10px 0px;
					}
				}

				.topBar {
					height: 10%;
				}
			}
		}
	}
</style>
