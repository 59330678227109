<template>
	<div class="mouthInfo">
		<div class="calendarInfo">
			<div class="date" @click="openMouth">
				<span>{{year}}</span>
				<span class="line"></span>
				<span>{{mouthDay}}</span>
			</div>
			<!-- 月数据 -->
			<div class="mouthDay" style="margin-top: 30px;">
				<div class="box" v-for="(item,index) in mouthList" :key="item"
					:class="[currentIndex == index? 'active':'']" @click="tabsclick(index)">
					{{item + '月'}}
				</div>
			</div>
			<!-- 出勤天数 -->
			<div class="attendceDay">
				<div>
					<p>{{AttendanceDay}}</p>
					<p>出勤天数</p>
				</div>
				<div class="">
					<p>{{LeaveDay}}</p>
					<p>请假天数</p>
				</div>
				<div class="">
					<p>{{RestDay}}</p>
					<p>休息天数</p>
				</div>
			</div>
			<!-- 底部天数类型选择 -->
	<!-- 		<div class="bottom" style="margin-top: 40px;">
				<topBar ref='topBar' :tabs="['出勤天数','请假天数','休息天数']" style="font-size: 14px;" @tabNum='tabClick'>
				</topBar>
				<div class="selectType">
					<div v-for="item in dayList" :key="item.Date">
						<div class="box" v-if="dayList.length > 0">
							<div class="left">
								{{item.Date}}
							</div>
							<div class="right" @click='dayDetails(item)'>
								<van-icon name="arrow" />
							</div>
						</div>
					</div>
					<div class="" v-show='dayList.length == 0'
						style="text-align: center;margin-top: 20px;font-size: 16px;">
						暂无数据
					</div>
				</div>
			</div> -->
		</div>

		<selcetMouth ref="selcetMouth" @timeChange='timeChange'></selcetMouth>

	</div>
</template>

<script>
	//import topBar from '@/components/common/topBar/index.vue'
	import selcetMouth from '@/components/common/selectMouth/index.vue'
	

	export default {
		data() {
			return {
				mouthList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
				currentIndex: Number(new Date().getMonth()),
				mouthDay: '', //选中的月份
				AttendanceDay: 0, //出勤天数
				RestDay: 0, //休息天数
				LeaveDay: 0, //请假天数
				year: '',
				//tabNum: 0,
				//dayList: [],
				//AttendanceDayDtos: [], //出勤天数数据
				//RestDayDtos: [], //休息天数数据
				//LeaveDayDtos: [], //请假天数数据
				ChildrenId: '',
			}
		},
		components: {
			//topBar,
			selcetMouth
		},
		mounted() {
			this._mouthDate()
			//this._getMouthAttence()
		},
		methods: {
			// 选中某月
			tabsclick(index) {
				this.currentIndex = index
				this.mouthDay = index + 1 + '月'
				this._getMouthAttence()
				// 初始为出勤天数
				this.$parent.tabNum = 0
				this.$parent.$refs.topBar.tabNum = 0
			},
			// 月数据(默认当前月)
			_mouthDate() {
				this.year = new Date().getFullYear()
				this.mouthDay = new Date().getMonth() + 1 + '月'
			},
			// 获取月下列表数据
			_getMouthAttence() {
				let model = {
					dayMonth: 2, //月
					year: this.year,
					month: this.currentIndex + 1,
					ChildrenId: this.ChildrenId,
				}
				this.$axios.get('/api/AttendanceStatistics/childrenAttendance',
					model
				).then(res => {
					//console.log(res)
					if (res.code == 200) {
						let attendanceDto = res.data.attendanceDto
						this.AttendanceDay = attendanceDto.AttendanceDay
						this.RestDay = attendanceDto.RestDay
						this.LeaveDay = attendanceDto.LeaveDay		
						this.$parent.RestDayDtos = attendanceDto.RestDayDtos			
						this.$parent.dayList = this.$parent.AttendanceDayDtos = attendanceDto.AttendanceDayDtos
						this.$parent.LeaveDayDtos = attendanceDto.LeaveDayDtos	
						this. $parent.ChildrenName = res.data.ChildrenName
						this.$parent.ProfilePicture = res.data.ProfilePicture
						// 默认展示出勤天数数据
						if (this.$parent.dayList.length > 0) {
							this.$parent.dayList.map(item => {
								item.Date = this.$moment(new Date(item.Date)).format("YYYY-MM-DD")
							})
						}
					}
				})
			},
			// 具体选中某一天
			// dayDetails(item) {
			// 	this.$emit('dayDadails', item)
			// },
			// 选择年月
			openMouth() {
				if (this.$parent.value) {
					this.$refs.selcetMouth.show = true
				}
			},
			// 改变年月
			timeChange(time) {	
				this.year = time.slice(0, 4)
				this.mouthDay = Number(time.slice(5)) + '月'
				this.currentIndex = Number(time.slice(5) - 1)
				//  初始在出勤天数高亮
				this.$parent.tabNum = 0
				this.$parent.$refs.topBar.tabNum = 0
				// 刷新数据
				this._getMouthAttence()
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>
