<template>
	<div class="parentAttendance">
		<!-- 日数据 -->
		<div class="dayInfo" style="margin-top: 10px">
			<van-calendar title="日历" :poppable="false" :show-confirm="false"  style="height: 50vh;"
				:show-title='false' @select='selectDay' ref="calendar" :min-date='minDate' :max-date='maxDate' />
		</div>
		<!-- 出勤统计数据 -->
		<div class="info" style="margin-top: 20px;padding: 0 10px;">
			<span>出勤统计:
				<span>{{childerState == 9 ? '请假': childerState == 1 ? '已签到':'今日休息'}}</span>
			</span>
		</div>
	</div>
</template>

<script>
	import {
		Calendar
	} from 'vant'

	export default {
		data() {
			return {
				year: '',
				childerState: '',
				minDate: new Date(new Date().getFullYear()-1, new Date().getMonth(), 1),
				maxDate: new Date(),
				ChildrenId: '',
			}
		},
		components: {
			[Calendar.name]: Calendar,
		},
		mounted() {
			this.currentDate()
			//this._getMoutnState()
		},
		methods: {
			//选中某天
			selectDay(data) {
				let selecDay = this.$moment(new Date(data)).format("YYYY-MM-DD")
				this.$parent.year = Number(selecDay.slice(0, 4))
				this.$parent.mouthDay = selecDay.slice(5)
				this.$parent.currentDay = selecDay
				this._getMoutnState()
			},
			// 当天数据
			currentDate() {
				let currentDate = this.$refs.calendar.currentDate
				let data = this.$moment(new Date(currentDate)).format("YYYY-MM-DD")
				this.$parent.year = Number(data.slice(0, 4))
				this.$parent.mouthDay = data.slice(5)
				this.$parent.currentDay = data
			},
			// 查看当前宝贝日考勤状态
			_getMoutnState() {
				let model = {
					date: this.$parent.currentDay,
					dayMonth: 1, //日
					ChildrenId: this.ChildrenId,
				}
				this.$axios.get('/api/AttendanceStatistics/childrenAttendance',
					model
				).then(res => {
					if (res.code == 200) {
						let workerAttendance = res.data.workerAttendance
						this.childerState = workerAttendance ? workerAttendance.ChildrenAttendanceType : ''
						if (this.ChildrenId) {
							this. $parent.ChildrenName = res.data.ChildrenName
							this.$parent.ProfilePicture = res.data.ProfilePicture
						}
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>

</style>
